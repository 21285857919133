<template>
	<div class="product_card_component">
		<!-- Temporary :style for mobile -->
		<ProductCard
			platform="TNGV"
			:images="getImages"
			:product-name="productName"
			:product-color="props.product?.organization_textile_item_code"
			:product-material="props.product.textile?.name || ''"
			:inventory="props.product.inventory?.totalMeters ?? 0"
			:price-per-meter="props.product.pricing?.adjusted_price ?? '-'"
			:production-order="'-'"
			:custom-textile="'-'"
			:is-favorite="false"
			:cta-url="textileUrl"
			@image-clicked="viewProduct(textileUrl)"
		/>
	</div>
</template>
<script lang="ts" setup>
import type { CatalogTextileItem, Image, PlatformCatalogProduct } from "@/types/products";

const props = defineProps<{
	product: PlatformCatalogProduct | CatalogTextileItem;
	organizationSlug: string;
}>();

const { imgixEcmOdcDomain } = useRuntimeConfig().public;

const productName = computed(() => {
	return `${props.product.textile?.organization_textile_code} (${props.product.textile?.organization_textile_name})`;
});

const getImages = computed(() => {
	return (
		[...props.product?.images_textiles_items]
			?.sort((a: Image, b: Image) => a.number - b.number)
			?.map((item: Image) => ({
				id: item.id,
				imageUrl: `https://${imgixEcmOdcDomain}/${item.image_path}?q=100&h=560&w=560&auto=compress`,
			})) || []
	);
});

const textileUrl = computed(
	() => `/${props.organizationSlug}/textiles/${props.product.textile_id}?item=${props.product.id}`
);

const $router = useRouter();
const viewProduct = (url: string) => {
	$router.push(url);
};
</script>
<style lang="scss" scoped>
// This is a little hack to make buttons smaller on active/default style
.product_card {
	:deep(.v-btn--size-large) {
		--v-btn-size: 14px !important;
		--v-btn-height: 40px !important;

		.v-btn__content {
			@apply text-[14px] leading-[20px] font-[400] py-[10px];
		}
	}
}

// TODO When info is available, no longer hide the following :
:deep(.product_card) {
	@apply h-[595px];

	.product_info > .product_state > .production_order,
	.product_info > .product_state > .custom_textile {
		@apply hidden;
	}

	.product_info > .product_state > .product_price {
		@apply border-b-0 #{!important};
	}

	.image_wrapper > .icon_wrapper {
		@apply hidden;
	}
	.product_material {
		@apply min-h-[28px];
	}
	.v-slide-group__container {
		@apply min-h-[48px];
	}
	.image_wrapper {
		@apply bg-neutral-50;
	}
}

:deep(.product_card.marketplace) {
	@apply h-[508px];
}
</style>
